import React, { Component } from "react";

import {
  Grid,
  MenuItem,
  Typography,
  Fab,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, Settings } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import { collectionActions } from "../../../reducers/collectionsReducer";
import { connect } from "react-redux";
import TextInput from "../../common/Components/TextInput";
import CheckboxInput from "../../common/Components/CheckboxInput";
import NumberInput from "../../common/Components/NumberInput";
import { DateInput } from "@oniti/oniti-inputs";
import MiseADispositionCss from "./css/MiseADispositionCss";
import LotMadNegoce from "../LotMadNegoce";
import DocumentPicker from "../../common/Components/DocumentPicker";

const LOCALSTORAGE_FILTERS_KEY = "mise-a-disposition-negoce-filters";

class MadNegoce extends Component {
  state = {
    openLotModal: false,
    filterSelectedStatus: ["ouvert", "ferme"],
    filterSelectedPriorities: ["all"],
    filterSelectedValidies: ["all"],
  };
  componentDidMount() {
    const { dispatch } = this.props;

    this.fetchMads();
    collectionActions(dispatch, "users", "INDEX", {
      params: {
        commerciaux: true,
      },
    });
  }

  getPrioritiesOptions(withAll = false) {
    let priorities = [
      { value: "all", label: "Toutes" },
      { value: "p1", label: "P1" },
      { value: "p2", label: "P2" },
      { value: "p3", label: "P3" },
    ];

    if (!withAll) {
      priorities = priorities.filter((priority) => priority.value !== "all");
    }

    return priorities.map((priority) => (
      <MenuItem key={priority.value} value={priority.value}>
        {priority.label}
      </MenuItem>
    ));
  }

  getValideOptions(withAll = false) {
    let validite = [
      { value: "all", label: "Toutes" },
      { value: true, label: "Oui" },
      { value: false, label: "Non" },
    ];

    if (!withAll) {
      validite = validite.filter((valid) => valid.value !== "all");
    }

    return validite.map((valid) => (
      <MenuItem key={valid.value} value={valid.value}>
        {valid.label}
      </MenuItem>
    ));
  }

  loadAsyncData(filters) {
    let { dispatch } = this.props;

    return collectionActions(
      dispatch,
      "mise-a-dispositions-negoce",
      "INDEX",
      null,
      null,
      true
    );
  }

  getHeader(mad) {
    const { classes } = this.props;
    return (
      <div className={classes.header + " " + classes.marginBottom}>
        {this.getStatutDiv(mad.statut)}
        {this.getToDiv(mad.poids_to_total)}
        {this.getSuperficieDiv(mad.surface_m2)}
        {this.getNbunitsDiv(mad.nb_unite)}
      </div>
    );
  }

  getToDiv(to) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{to} To</div>;
  }

  getSuperficieDiv(superficie) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{superficie} M</div>;
  }

  getNbunitsDiv(nb) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{nb}</div>;
  }

  getCommentaire(commentaire, length = 50) {
    if (!commentaire) return "";
    if (commentaire.length > length) {
      return commentaire.substring(0, length) + "...";
    }
    return commentaire;
  }

  getCommentairePart(title, commentaire, length = 50) {
    if (!commentaire) return null;
    const { classes } = this.props;
    return (
      <div className={classes.commentaireContainer}>
        <div>{title}</div>
        <div>{this.getCommentaire(commentaire, length)}</div>
      </div>
    );
  }

  getImagesPreview(image) {
    const { classes } = this.props;
    return (
      <img
        className={classes.preview_image}
        src={image.url}
        alt={image.filename}
      />
    );
  }

  getStatutDiv(statut) {
    if (!statut) return null;
    let color = null;
    switch (statut) {
      case "ouvert":
        color = "rgb(15 143 15)";
        break;
      case "ferme":
        color = "rgb(239 129 34)";
        break;
      default:
        color = null;
    }
    const label = statut
      .split("-")
      .map((word) => word[0].toUpperCase())
      .join("-");
    const { classes } = this.props;
    return (
      <div
        className={classes.headerDivInfo}
        style={{
          backgroundColor: color,
        }}
        title={statut}
      >
        {label}
      </div>
    );
  }

  getLotConditionnementLibelle(lots) {
    if (!lots || lots.length === 0) return null;
    return lots.map((lot) => {
      return (
        <div key={lot.uuid}>
          {lot.conditionnement_nom} / {lot.article_nom}
        </div>
      );
    });
  }

  displayMad(mad, options) {
    const { onClickEditHandler } = options;
    const { classes } = this.props;

    return (
      <div
        key={mad.uuid}
        className={classes.madCard}
        onClick={() => {
          this.setState({
            enableRegisterScroll: false,
          });
          onClickEditHandler(mad);
        }}
      >
        <div
          className={classes.infos}
          style={{
            height: "100%",
          }}
        >
          <div className={classes.reference + " " + classes.marginBottom}>
            <span>{mad.reference}</span>
          </div>
          <div className={classes.body}>
            <div className={classes.article + " " + classes.marginBottom}>
              {mad.liste_lots}
            </div>
            <hr />
            {this.getHeader(mad)}
            <hr />
            {this.getCommentairePart("Commentaire", mad.commentaire_general)}
            <div className={classes.photoIconContainer}>
              {this.renderFirstDocuments(mad)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFirstDocuments(mad) {
    const { classes } = this.props;
    return mad.lots_firsts_images.map((lot, index) => {
      return (
        <div className={classes.preview_image_container}>
          {this.getImagesPreview(lot)}
        </div>
      );
    });
    // .slice(0, 3).
  }

  renderDataCustomCallback(data, options) {
    const { ccrudOptions } = this.state;
    if (!ccrudOptions) {
      this.setState({ ccrudOptions: options });
    }

    return this.listing(data, options);
  }

  listing(data, options) {
    const { classes } = this.props;
    return (
      <Grid container className={classes.madContainer}>
        {data.map((mad) => this.displayMad(mad, options))}
      </Grid>
    );
  }

  onChangeFilterHandler(name, e) {
    let values = e.target.value;

    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState({ [name]: values }, () => {
      this.fetchMads();
      this.saveFilters();
    });
  }

  saveFilters() {
    const {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterRecherche,
      filterSelectedValidies,
      viewMobile,
    } = this.state;
    const filters = {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedValidies,
      filterSelectedSitesUuid,
      filterRecherche,
      viewMobile,
    };
    localStorage.setItem(LOCALSTORAGE_FILTERS_KEY, JSON.stringify(filters));
  }

  loadFilters() {
    const filters = JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTERS_KEY));
    if (filters) {
      this.setState(filters);
    }
  }

  fetchMads(timeout = 0) {
    const {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterSelectedValidies,
      filterSortBy,
    } = this.state;
    const params = {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedValidies,
      filterSelectedSitesUuid,
      filterSortBy,
    };
    clearTimeout(this.state.fetchRequest);
    // eslint-disable-next-line
    this.state.fetchRequest = setTimeout(() => {
      collectionActions(
        this.props.dispatch,
        "mise-a-dispositions-negoce",
        "INDEX_WITHOUT_DISPATCH",
        {
          params,
        }
      ).then((response) => {
        this.setState({ localData: response.data });
      });
    }, timeout);
  }

  getStatusOptions() {
    const status = [
      { value: "all", label: "Tous" },
      { value: "ouvert", label: "Ouvert" },
      { value: "ferme", label: "Fermé" },
    ];

    return status.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  }

  getPrioritiesOptions(withAll = false) {
    let priorities = [
      { value: "all", label: "Toutes" },
      { value: "p1", label: "P1" },
      { value: "p2", label: "P2" },
      { value: "p3", label: "P3" },
    ];

    if (!withAll) {
      priorities = priorities.filter((priority) => priority.value !== "all");
    }

    return priorities.map((priority) => (
      <MenuItem key={priority.value} value={priority.value}>
        {priority.label}
      </MenuItem>
    ));
  }

  getAddBtn(onClickAddHandler) {
    if (!onClickAddHandler) return null;
    const { classes } = this.props;
    return (
      <div className={classes.contentCenter}>
        <Fab
          color="primary"
          size="small"
          aria-label="Add"
          onClick={onClickAddHandler}
          className={classes.addButton}
        >
          <Add />
        </Fab>
      </div>
    );
  }

  header() {
    const { ccrudOptions } = this.state;

    const { onClickAddHandler } = ccrudOptions || {};
    const {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedValidies,
    } = this.state;
    const { classes, madsNegoceStore } = this.props;

    return (
      <Grid container>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Status"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedStatus"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedStatus,
            }}
            select
          >
            {this.getStatusOptions()}
          </TextInput>
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Offres valides"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedValidies"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedValidies,
            }}
            select
          >
            {this.getValideOptions(true)}
          </TextInput>
        </Grid>
        <Grid item lg={3} xs={12}>
          {this.getAddBtn(onClickAddHandler)}
        </Grid>
      </Grid>
    );
  }

  /**
   * Traite les actions remontées par la modale de création de Lot.
   *
   * À la création d'un Lot, l'action 'close' est générée avant
   * l'action 'create'.
   *
   * @param {string} action - L'identifiant de l'action.
   * @param {Lot}    lot    - L'objet Lot retourné par l'action.
   */
  handleLotModalActions(action, lot, clicknext) {
    if (action === "close") {
      this.setState({ openLotModal: false });
    }
    if (action === "create" || action === "update") {
      this.setState({ openLotModal: false }, () => {
        collectionActions(
          this.props.dispatch,
          "mise-a-dispositions-negoce",
          "RESET_DETAIL",
          null,
          (mad) => {
            this.setState({ openLotModal: true }, () => {
              this.setState({ selectedMad: mad });
            });
          }
        );
      });
    } else if (action === "update") {
      this.setState({ openLotModal: true });
    }
  }

  /**
   * Permet de charger les sites suite au changement dans le formulaire d'autocomplete
   * @param  {[type]} entreprise_uuids [description]
   * @return {[type]}                  [description]
   */
  getSitesFromUuids(entreprise_uuids) {
    collectionActions(this.props.dispatch, "etablissements", "INDEX", {
      params: { entreprise_uuids },
    });
  }

  getEtablissements(entreprise_uuid) {
    if (!this.props.etablissementsStore.list) {
      return [];
    }
    let list = this.props.etablissementsStore.list.slice(); // Pprofshallow copy
    if (entreprise_uuid) {
      list = list.filter((e) => e.entreprise_uuid === entreprise_uuid);
    }
    return list.map((e) => {
      return (
        <MenuItem value={e.uuid} key={e.uuid}>
          {e.nom}
          {!!e.ville ? " (" + e.ville.toUpperCase() + ")" : ""}
        </MenuItem>
      );
    });
  }

  generateCommercialSelect() {
    const { usersStore, classes } = this.props;
    if (!usersStore.list) return null;
    const commerciaux = [...usersStore.list];
    return commerciaux.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}
        </MenuItem>
      );
    });
  }

  getLabelColumnPrix(statut_achat) {
    if (statut_achat === "oui") {
      return "€/t achat";
    }
    if (statut_achat === "offre_en_cour") {
      return " €/t offre";
    }
    return "€/t estimé";
  }

  getCreateUpdateModalContent(mad, madsNegoceStore, onChangeHandler) {
    const { classes } = this.props;

    const { selectedMadsUuids, openLotModal } = this.state;

    let {
      uuid,
      reference,
      description,
      statut,
      conlusion,
      date_fin,
      is_disponible,
      date_disponibilite,
      commentaire_disponibilite,
      date_fin_lot,
      commentaire_lot,
      is_complet,
      nb_unite,
      surface_m2,
      priorite,
      statut_achat,
      validite_offre,
      clients_potentiels,
      commentaire_general,
      is_valid,
      validated_at,
      valid_user_full_name,
      spot_recurrent,
      entreprise_uuid,
      etablissement_uuid,
      commercial_uuid,
    } = mad;

    return (
      <>
        {/* <Grid container spacing={1} alignItems="flex-end"></Grid> */}
        <Grid container className={[classes.section]}>
          <Grid container xs={12} lg={6} className={[classes.border]}>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Référence"
                value={reference}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="reference"
                onChangeHandler={onChangeHandler}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                id="commercial_uuid"
                label="Commercial"
                value={commercial_uuid}
                required={true}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="commercial_uuid"
                onChangeHandler={(name, e) => {
                  onChangeHandler(name, e);
                }}
                className={classes.selectContainer}
                fullWidth
                select
              >
                {this.generateCommercialSelect()}
              </TextInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Statut"
                value={statut}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="statut"
                onChangeHandler={onChangeHandler}
                fullWidth
                select
              >
                <MenuItem value="ouvert">Ouvert</MenuItem>
                <MenuItem value="ferme">Fermé</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Conclusion"
                value={conlusion}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="conlusion"
                onChangeHandler={onChangeHandler}
                fullWidth
                select
                disabled={statut == "ouvert"}
              >
                <MenuItem value="interet_gagne">Intérêt Gagné</MenuItem>
                <MenuItem value="interet_perdu">Intérêt Perdu</MenuItem>
                <MenuItem value="interet_pas_de_client">
                  Intérêt Pas de Client
                </MenuItem>
                <MenuItem value="pas_d_interet">Pas d'Intérêt</MenuItem>
              </TextInput>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid item xs={12} lg={12}>
              <label htmlFor="description" style={{ marginLeft: "5px" }}>
                Description
              </label>
              <textarea
                id="description"
                onChange={(e) => onChangeHandler("description", e)}
                rows="6"
                style={{ marginLeft: "5px", width: "99%" }}
                placeholder="Description"
                value={description}
              />
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid item xs={12} lg={3}>
            <TextInput
              id="entreprise_uuid"
              label="Fournisseur"
              value={entreprise_uuid}
              required={true}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="entreprise_uuid"
              onChangeHandler={(name, e) => {
                this.getSitesFromUuids([e.target.value]);
                onChangeHandler(name, e);
              }}
              className={classes.selectContainer}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "entreprises",
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextInput
              id="etablissement_uuid"
              label="Site"
              disabled={!entreprise_uuid}
              value={etablissement_uuid}
              required={true}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="etablissement_uuid"
              onChangeHandler={(name, e) => {
                onChangeHandler(name, e);
              }}
              className={classes.selectContainer}
              fullWidth
              select
            >
              {this.getEtablissements(entreprise_uuid)}
            </TextInput>
          </Grid>

          <Grid item xs={12} lg={2}>
            <DateInput
              id="date_fin"
              label="Date de fin de la Mad"
              format="dd/MM/yyyy"
              value={date_fin}
              collectionStore={madsNegoceStore}
              margin="normal"
              name="date_fin"
              onChangeHandler={onChangeHandler}
            />
          </Grid>

          <Grid container xs={12} lg={12} className={[classes.border]}>
            <Grid xs={12} lg={3}>
              <Grid item xs={12} lg={1} style={{ paddingTop: "1rem" }}>
                <CheckboxInput
                  id="is_disponible"
                  label="Disponible"
                  value={is_disponible}
                  margin="normal"
                  collectionStore={madsNegoceStore}
                  name="is_disponible"
                  onChangeHandler={onChangeHandler}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <DateInput
                  id="date_disponibilite"
                  label="Date dispo"
                  format="dd/MM/yyyy"
                  value={date_disponibilite}
                  collectionStore={madsNegoceStore}
                  margin="normal"
                  name="date_disponibilite"
                  onChangeHandler={onChangeHandler}
                  disabled={is_disponible}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={9}>
              <label
                htmlFor="commentaire_disponibilite"
                style={{ marginLeft: "5px" }}
              >
                Commentaire disponibilité
              </label>
              <textarea
                id="commentaire_disponibilite"
                onChange={(e) =>
                  onChangeHandler("commentaire_disponibilite", e)
                }
                rows="6"
                style={{ marginLeft: "5px", width: "99%" }}
                placeholder="Commentaire disponibilité"
                value={commentaire_disponibilite}
                disabled={is_disponible}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} lg={12} className={[classes.border]}>
            <Grid xs={12} lg={3}>
              <Grid item xs={12} lg={12} className={classes.checkBoxInputField}>
                <RadioGroup
                  name="S/R"
                  className={classes.group}
                  value={spot_recurrent}
                  onChange={(e) => {
                    onChangeHandler("spot_recurrent", e);
                  }}
                  row
                >
                  <FormControlLabel
                    value="spot"
                    control={<Radio color="primary" />}
                    label="Spot"
                  />
                  <FormControlLabel
                    value="recurrent"
                    control={<Radio color="primary" />}
                    label="recurrent"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} lg={12}>
                <DateInput
                  id="date_fin_lot"
                  label="Date fin du lot"
                  format="dd/MM/yyyy"
                  value={date_fin_lot}
                  collectionStore={madsNegoceStore}
                  margin="normal"
                  name="date_fin_lot"
                  onChangeHandler={onChangeHandler}
                  disabled={spot_recurrent === "spot"}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} lg={9}>
              <label htmlFor="commentaire_lot" style={{ marginLeft: "5px" }}>
                Commentaire lot
              </label>
              <textarea
                id="commentaire_lot"
                onChange={(e) => onChangeHandler("commentaire_lot", e)}
                rows="6"
                style={{ marginLeft: "5px", width: "99%" }}
                placeholder="Commentaire lot"
                value={commentaire_lot}
              />
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid container xs={12} lg={12} className={[classes.border]}>
            <Grid item xs={12} lg={2} style={{ paddingTop: "1rem" }}>
              <CheckboxInput
                id="is_complet"
                label="Complet"
                value={is_complet}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="is_complet"
                onChangeHandler={onChangeHandler}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <NumberInput
                id="nb_unite"
                label="Nb unités"
                value={nb_unite}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="nb_unite"
                onChangeHandler={onChangeHandler}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <NumberInput
                id="surface_m2"
                label="Surface (m2 au sol)"
                value={surface_m2}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="surface_m2"
                onChangeHandler={onChangeHandler}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextInput
                label="Statut achat"
                value={statut_achat}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="statut_achat"
                onChangeHandler={onChangeHandler}
                fullWidth
                select
              >
                <MenuItem value="oui">Oui</MenuItem>
                <MenuItem value="non">Non</MenuItem>
                <MenuItem value="offre_en_cour">Offre en cour</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={12} lg={2}>
              <DateInput
                id="validite_offre"
                label="Validité offre"
                format="dd/MM/yyyy"
                value={validite_offre}
                collectionStore={madsNegoceStore}
                margin="normal"
                name="validite_offre"
                onChangeHandler={onChangeHandler}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <label htmlFor="clients_potentiels" style={{ marginLeft: "5px" }}>
              Clients potentiels
            </label>
            <textarea
              id="clients_potentiels"
              onChange={(e) => onChangeHandler("clients_potentiels", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Clients potentiels"
              value={clients_potentiels}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <label htmlFor="commentaire_general" style={{ marginLeft: "5px" }}>
              Commentaire général
            </label>
            <textarea
              id="commentaire_general"
              onChange={(e) => onChangeHandler("commentaire_general", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Commentaire général"
              value={commentaire_general}
            />
          </Grid>
        </Grid>
        {mad.uuid ? (
          <>
            <hr className={classes.hr} />
            <LotMadNegoce
              actionsCallback={this.handleLotModalActions.bind(this)}
              mise_a_disposition_negoce_uuid={uuid}
              createUpdateModalNext={"Suivant"}
              //  modal={{ only: true, open: openLotModal }}
              datas={mad.lot_mad_negoces}
              pagination={false}
              search={true}
              labelPrix={this.getLabelColumnPrix(mad.statut_achat)}
            />
          </>
        ) : null}
        <hr className={classes.hr} />
        <Grid container className={[classes.section, classes.borderConclusion]}>
          <Grid item xs={12} lg={4} style={{ paddingTop: "1rem" }}>
            <CheckboxInput
              id="is_valid"
              label="Validé"
              value={is_valid}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="is_valid"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DateInput
              id="validated_at"
              label="Validité le"
              format="dd/MM/yyyy"
              value={validated_at}
              collectionStore={madsNegoceStore}
              margin="normal"
              name="validite_offre"
              onChangeHandler={onChangeHandler}
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Validé par"
              value={valid_user_full_name}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="valid_user_full_name"
              onChangeHandler={onChangeHandler}
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </>
    );
  }

  filterLocalData() {
    const { filterRecherche, viewMobile } = this.state;
    const { localData } = this.state;
    if (!filterRecherche || !viewMobile) return localData;
    return localData.filter((mad) => {
      const keys = Object.keys(mad);
      return keys.some((key) => {
        if (typeof mad[key] === "string") {
          return mad[key].toLowerCase().includes(filterRecherche.toLowerCase());
        }
        return false;
      });
    });
  }

  getCommentaireTd(commentaire, length = 20) {
    if (!commentaire) return null;
    return (
      <span
        title={commentaire}
        style={{
          cursor: "help",
        }}
      >
        {commentaire.substring(0, length)}
        {commentaire.length > length ? "..." : ""}
      </span>
    );
  }

  getCheckBox(obj, key, readOnly = false) {
    return (
      <Checkbox
        checked={obj[key]}
        color="primary"
        disabled={readOnly}
        onChange={(e) => {
          collectionActions(
            this.props.dispatch,
            "mise-a-dispositions-negoce",
            "UPDATE",
            {
              ...obj,
              [key]: e.target.checked,
            }
          ).then(() => {
            this.fetchMads();
          });
        }}
      />
    );
  }

  getCheckBoxReadOnly(obj, key) {
    return this.getCheckBox(obj, key, true);
  }

  // Retourn la date formatée
  getDate(string, options = null) {
    if (!string) return null;

    let date = new Date(string);
    options = options || {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }

  getCellsConfig() {
    const { classes } = this.props;

    return [
      {
        datakey: "reference",
        title: "Référence",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "statut_libelle",
        title: "Statut",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "description",
        title: "Description",
        sortable: true,
        searchable: true,
        format: (data, key) => {
          return this.getCommentaireTd(data[key], 50);
        },
        className: classes.longDescription,
      },
      {
        datakey: "is_valid",
        title: "Valide",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format: this.getCheckBox.bind(this),
      },
      {
        datakey: "spot_recurrent",
        title: "S/R",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "is_disponible",
        title: "Dispo",
        sortable: true,
        searchable: true,
        format: this.getCheckBoxReadOnly.bind(this),
      },
      {
        datakey: "statut_achat",
        title: "Achete",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "liste_lots",
        title: "Articles",
        sortable: false,
        searchable: false,
        className: classes.listeLots,
      },
      {
        datakey: "commentaire_general",
        title: "Commentaire Général",
        sortable: true,
        searchable: true,
        format: (data, key) => {
          return this.getCommentaireTd(data[key], 50);
        },
        className: classes.longDescription,
      },
      {
        datakey: "clients_potentiels",
        title: "Clients potentiels",
        sortable: true,
        searchable: true,
        format: (data, key) => {
          return this.getCommentaireTd(data[key], 100);
        },
        className: classes.longDescription,
      },
      {
        datakey: "date_fin",
        title: "Date de fin",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
    ];
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de la MaD Negoce"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette mise à disposition negoce ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(mad) {
    let title = !!mad
      ? mad.reference
      : "Ajout d'une nouvelle mise à disposition negoce";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getModeView() {
    return (
      <Grid container>
        <Grid item xs={3}>
          <CheckboxInput
            label="Vue mobile"
            a
            value={this.state.viewMobile}
            onChangeHandler={(name, e) => {
              this.setState(
                { viewMobile: e.target.value, ccrudOptions: null },
                () => {
                  this.saveFilters();
                }
              );
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  actionsCallback(action, data) {
    switch (action) {
      case "delete":
      case "create":
      case "update":
        this.fetchMads();
        break;
      case "click-edit":
        this.getSitesFromUuids([data.entreprise_uuid]);
        break;
      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { viewMobile } = this.state;

    return (
      <Typography component="div" dir={"ltr"} style={{ padding: 8 * 3 }}>
        {this.getModeView()}
        {this.header()}
        <CollectionCrud
          collectionName={"mise-a-dispositions-negoce"}
          loadDatas={true}
          datas={this.filterLocalData()}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          fullScreenModal={true}
          cancelUpdateCheck={true}
          rights={{
            create: "admin-crud-mises-a-disposition-negoce",
            edit: "admin-crud-mises-a-disposition-negoce",
            delete: "admin-crud-mises-a-disposition-negoce",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          renderDataCustomCallback={
            viewMobile ? this.renderDataCustomCallback.bind(this) : null
          }
          defaultValues={{
            priorite: "p1",
            complet: false,
          }}
          modalContentStyle={{}}
          actionsCallback={this.actionsCallback.bind(this)}
          cellsConfig={this.getCellsConfig()}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "reference",
            sortType: "desc",
            nodatalabel: "Aucune Mad",
            rowsPerPageOptions: [25, 50],
          }}
          // additionnalControllers={[this.getDocumentBtn.bind(this)]}
          disabledEnterModal={true}
          persistDatatableOptions={{
            id: "mad_negoce_list",
            exipiration_minutes: 60 * 12, // 12 Heures
          }}
        />
      </Typography>
    );
  }
}

MadNegoce = withStyles(MiseADispositionCss)(MadNegoce);

MadNegoce = connect((store) => {
  return {
    madsNegoceStore: store.collections["mise-a-dispositions-negoce"],
    etablissementsStore: store.collections.etablissements,
    user: store.auth.user,
    usersStore: store.collections.users,
  };
})(MadNegoce);

export default MadNegoce;
